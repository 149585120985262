import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes: [
    {
      path: '/',
      name: 'home',
      component: () => import('@/views/Home.vue'),
      meta: {
        pageTitle: '',
      },
    },
    {
      path: '/live',
      name: 'home',
      component: () => import('@/views/Home.vue'),
      meta: {
        pageTitle: 'Homepage',
      },
    },
    {
      path: '/categories',
      name: 'categories',
      component: () => import('@/views/Categories.vue'),
      meta: {
        pageTitle: 'Categories',
      },
    },
    {
      path: '/downloads',
      name: 'downloads',
      component: () => import('@/views/Downloads.vue'),
      meta: {
        pageTitle: 'Downloads',
      },
    },
    {
      path: '/register',
      name: 'auth-register',
      component: () => import('@/views/Auth/Register.vue'),
      meta: {
        layout: 'full',
        resource: 'Auth',
        redirectIfLoggedIn: true,
      },
    },
    {
      path: '/requestReset',
      name: 'auth-forgot-password',
      component: () => import('@/views/Auth/requestPasswordReset.vue'),
      meta: {
        layout: 'full',
        resource: 'Auth',
        redirectIfLoggedIn: true,
      },
    },
    {
      path: '/resetpassword',
      name: 'reset-password',
      component: () => import('@/views/Auth/resetPassword.vue'),
      meta: {
        layout: 'full',
        resource: 'Auth',
        redirectIfLoggedIn: true,
      },
    },
    {
      path: '/HelpCenter',
      name: 'HelpCenter',
      component: () => import('@/views/Platform/HelpCenter.vue'),
      meta: {
        pageTitle: 'Help Center',
      },
    },
    {
      path: '/ChannelMenu',
      name: 'ChannelMenu',
      component: () => import('@/views/ChannelMenu.vue'),
      meta: {
        pageTitle: 'Help Center',
      },
    },
    {
      path: '/pages/account-setting',
      name: 'pages-account-setting',
      component: () => import('@/views/Account/AccountSetting.vue'),
      meta: {
        pageTitle: 'Account Settings',
        breadcrumb: [
        ],
      },
    },
    {
      path: '/BrimePro',
      name: 'BrimePro',
      component: () => import('@/views/Platform/BrimePro.vue'),
      meta: {
        pageTitle: 'Brime Pro™',
      },
    },
    {
      path: '/billing',
      name: 'billing',
      component: () => import('@/views/Billing/Billing.vue'),
      meta: {
        pageTitle: 'Billing',
      },
    },
    {
      path: '/videos/:id',
      name: 'videos',
      component: () => import('@/views/Videos.vue'),
    },
    {
      path: '/:id/clips',
      name: 'Clips',
      component: () => import('@/views/Clips/MyClips.vue'),
    },
    {
      path: '/stats',
      name: 'stats',
      component: () => import('@/views/Platform/Stats.vue'),
      meta: {
        pageTitle: 'Platform Stats',
      },
    },
    {
      path: '/clips',
      name: 'clips',
      component: () => import('@/views/ClipBattles.vue'),
      meta: {
        pageTitle: 'Clip Review 👏',
      },
    },
    {
      path: '/category/:slug',
      name: 'categories',
      props: true,
      component: () => import('@/views/Category.vue'),
    },
    {
      path: '/clip/:id',
      props: true,
      component: () => import('@/views/Clips/ClipViewer.vue'),
    },
    {
      path: '/login',
      name: 'login',
      component: () => import('@/views/Auth/Login.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/error-404',
      name: 'error-404',
      component: () => import('@/views/error/Error404.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/:name/chat',
      props: true,
      component: () => import('@/views/obs-widgets/Chat.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/obs-widgets/stream',
      props: true,
      component: () => import('@/views/obs-widgets/StreamSettings.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/:name',
      props: true,
      component: () => import('@/views/Channel.vue'),
    },
    {
      path: '/channel/:name',
      redirect: '/:name',
    },
    {
      path: '*',
      redirect: 'error-404',
    },
  ],
})

// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById('loading-bg')
  if (appLoading) {
    appLoading.style.display = 'none'
  }
})

export default router
